'use client';
import FormFieldNoDesc from '@/components/form/form-field';
import { Button } from '@/components/ui/button';
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from '@/components/ui/card';
import { Form } from '@/components/ui/form';
import { loginUser } from '@/lib/api';
import type { LoginForm, LoginResponse } from '@/types/types';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	type MutationObserverErrorResult,
	useMutation,
} from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { type ZodType, z } from 'zod';
import { setCookie } from 'cookies-next';
import { useUserStore } from '@/hooks/use-user-store';

const schema: ZodType<LoginForm> = z.object({
	username: z
		.string({ invalid_type_error: 'Kullanıcı adını düzgün girin' })
		.max(16),
	password: z.string().trim().min(8).max(255),
});

export default function Login() {
	const router = useRouter();
	const userStore = useUserStore((state) => state);

	const loginMutation = useMutation<
		LoginResponse,
		MutationObserverErrorResult,
		LoginForm
	>({
		mutationKey: ['login'],
		mutationFn: loginUser,
	});

	const form = useForm<LoginForm>({
		resolver: zodResolver(schema),
	});

	const submitData: SubmitHandler<LoginForm> = (values, e) => {
		e?.preventDefault();
		loginMutation.mutate(values, {
			onSuccess: (data) => {
				if (data.status === 'success') {
					setCookie('authToken', data?.data.token, {
						maxAge: 86400,
						expires: new Date(Date.now() + 86400 * 1000),
						sameSite: 'lax',
						secure: true,
					});
					const user = {
						username: data?.data.result.username,
						roleID: data?.data.result.roleID,
						warehouseID: data?.data.result.warehouseID,
					};
					userStore.setUser(user);
					toast.success(data?.data.result.message, {
						description: 'Yönlendiriliyorsunuz...',
						action: {
							label: 'Kapat',
							onClick: () => toast.dismiss(),
						},
					});
					setTimeout(() => {
						router.push('/');
					}, 500);
				}
				if (data.status === 'error') {
					toast.error(data?.data.result.message, {
						action: {
							label: 'Kapat',
							onClick: () => toast.dismiss(),
						},
					});
				}
			},
			onError: () => {
				toast.error('Giriş başarısız', {
					action: {
						label: 'Kapat',
						onClick: () => toast.dismiss(),
					},
				});
			},
		});
	};

	return (
		<div className='relative flex min-h-screen flex-col items-center justify-center overflow-hidden'>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(submitData)}>
					<div className='m-auto w-full min-w-[24dvw] lg:max-w-lg'>
						<Card>
							<CardHeader className='space-y-1'>
								<CardTitle className='text-center text-2xl'>
									{' '}
									Giriş
								</CardTitle>
								<CardDescription className='text-center'>
									Giriş yapmak için kullanıcı adı ve e-posta
									girin
								</CardDescription>
							</CardHeader>
							<CardContent className='grid gap-4'>
								<div className='grid gap-2'>
									<FormFieldNoDesc
										label='Kullanıcı Adı'
										type='text'
										name='username'
									/>
								</div>
								<div className='grid gap-2'>
									<FormFieldNoDesc
										label='Şifre'
										type='password'
										name='password'
									/>
								</div>
							</CardContent>
							<CardFooter className='flex flex-col'>
								<Button
									className='w-full'
									type='submit'
									disabled={loginMutation.isPending}
								>
									{loginMutation.isPending
										? 'Giriş yapılıyor...'
										: 'Giriş yap'}
								</Button>
							</CardFooter>
						</Card>
					</div>
				</form>
			</Form>
		</div>
	);
}
